
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "frequency-report",
  components: {},
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;

    onMounted(() => {
      setCurrentPageTitle("Survey Analysis");
      setCurrentPageBreadcrumbs("Frequency Report", ["Survey Analysis"]);
    });

    return {
      currentUser,
    };
  },
});
