<template>
  <div>
    <div class="card">
      <!--begin::Body-->
      <div class="card-body p-lg-17">
        <!--begin::Row-->
        <div class="row mb-3">
          <!--begin::Col-->
          <div class="col-md-6 pe-lg-10">Hello</div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "frequency-report",
  components: {},
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;

    onMounted(() => {
      setCurrentPageTitle("Survey Analysis");
      setCurrentPageBreadcrumbs("Frequency Report", ["Survey Analysis"]);
    });

    return {
      currentUser,
    };
  },
});
</script>
